import { useEffect, useState } from 'react'
import * as services from './services/api';
import Card from './components/Card';
import Stats from './components/Stats';
import './App.css';

function App() {
  const [cards, setCards] = useState([])
  const [tab, setTab] = useState(0)
  const [count, setCount] = useState(0)
  const [unicCard, setUnicCard] = useState(0)

  const getArcane = (data) => {
    switch (data.title) {
      case "1": return "Le Bateleur";
      case "2": return "La Papesse";
      case "3": return "L'Impératrice";
      case "4": return "L'Empereur";
      case "5": return "Le Pape";
      case "6": return "L'Amoureux";
      case "7": return "Le Chariot";
      case "8": return "La Justice";
      case "9": return "L'Ermite";
      case "10": return "La Roue de fortune";
      case "11": return "La Force";
      case "12": return "Le Pendu";
      case "13": return "La Mort";
      case "14": return "Tempérance";
      case "15": return "Le Diable";
      case "16": return "La Tour";
      case "17": return "L'Étoile";
      case "18": return "La Lune";
      case "19": return "Le Soleil";
      case "20": return "Le Jugement";
      case "21": return "Le Monde";
      case "22": return "Le Fou";
    }
  }
  const getTitle = (data) => {
    let title = data.title
    switch (data.title) {
      case "11": title = "Valet"; break;
      case "12": title = "Cavalier"; break;
      case "13": title = "Reine"; break;
      case "14": title = "Roi"; break;
      default: title = title; break
    }
    switch (data.symbol) {
      case "1": title += " de baton"; break;
      case "2": title += " de coupe"; break;
      case "3": title += " d'épée"; break;
      case "4": title += " de denier"; break;
      default: title += ""; break;
    }
    if (data.is_arcane === "1") {
      title = getArcane(data)
    }
    return title
  }

  useEffect(() => {
    if (cards.length === 0) {
      services.getCards().then(data => {
        let card = [...data]
        let cardCount = 0
        let unicCardCount = 0
        card.map((value) => {
          value.title = getTitle(value)
          value.display = 1
          cardCount += parseInt(value.count)
          if(parseInt(value.count) > 0) {
            unicCardCount += 1
          }
        })
        setCards(card)
        setCount(cardCount)
        setUnicCard(unicCardCount)
      }).catch(err => {
        console.log(err);
      })
    }
  }, [cards, getTitle])

  const handleSearch = (e) => {
    let data = [...cards]
    data.map((card) => {
      card.title.toUpperCase().indexOf(e.currentTarget.value.toUpperCase()) > -1 ? card.display = 1 : card.display = 0
    })
    setCards(data)
  }
  const handleDiffDate = () => {
    let firstDate = new Date("01/01/2023");
    let today = new Date();
    let timeDiff = today.getTime() - firstDate.getTime()
    let daysDiff = timeDiff / (1000 * 3600 * 24)
    return Math.round(daysDiff)
  }
  return (
    <main className="min-h-screen">
      <header className='mb-8 flex justify-center'>
        <h1 className='text-6xl'>Suivi de tarot</h1>
      </header>
      <div className='mb-6 flex justify-center'>
        Total de cartes : {count}<br/>
        Cartes uniques : {unicCard}<br/>
        {handleDiffDate()} jours depuis le 01/01/2023
      </div>
      <div className='mb-6 flex justify-center'>
        <ul className='list-none flex'>
          <li className={`px-4 py-2 cursor-pointer ${tab === 0 ? "bg-black text-white" : "bg-white"} rounded-l-lg`} onClick={() => setTab(0)}>Ajout</li>
          <li className={`px-4 py-2 cursor-pointer ${tab === 1 ? "bg-black text-white" : "bg-white"} rounded-r-lg`} onClick={() => setTab(1)}>Statistiques</li>
        </ul>
      </div>
      <div className={`${tab === 0 ? "flex" : "hidden"} items-center flex-col`}>
        <input type='text' placeholder='Rechercher un carte' className='mb-8 px-4 py-2' onChange={handleSearch} />
        <div className='md:w-2/4'>
          <h2 className='text-3xl mb-6'>Anges</h2>
          <ul className='flex items-center flex-col mb-10'>
            {
              cards.map((card, i) => card.type === "angel" && <Card card={card} key={i} />)
            }
          </ul>
          <h2 className='text-3xl mb-6'>Démons</h2>
          <ul className='flex items-center flex-col mb-10'>
            {
              cards.map((card, i) => card.type === "devil" && <Card card={card} key={i} />)
            }
          </ul>
          <h2 className='text-3xl mb-6'>Animaux</h2>
          <ul className='flex items-center flex-col mb-10'>
            {
              cards.map((card, i) => card.type === "animal" && <Card card={card} key={i} />)
            }
          </ul>
        </div>
      </div>
      <div className={`${tab === 1 ? "flex" : "hidden"} items-center flex-col`}>
        <Stats cards={cards} />
      </div>
    </main>
  )
}

export default App;
