import { useEffect, useState } from "react"

function Stats({cards}) {
    const [cardSort, setCardSort] = useState([])
    useEffect(() => {
        if(cardSort.length === 0 && cards[0] !== undefined) {
            let sortList = [...cards]
            sortList.sort((a, b) => b.count - a.count)
            let newCards = []
            for(let i = 0; i < 5; i++) {
                newCards.push(sortList[i])
            }
            setCardSort(newCards)
        }
    }, [cards, cardSort])
    return (
        <div className="mb-8">
            <ul className="mb-6">
            {
                cardSort.map((value, i) => {
                    return (
                        <li key={i}>
                            La carte {value.title} ({value.type}) est revenu {value.count} fois
                        </li>
                    )
                })
            }
            </ul>
            <h2 className="text-xl font-medium mb-4">Voici les cartes anges à découvrir</h2>
            <ol className="list-decimal pl-10 mb-4">
                {
                    cards.map((value, i) => {
                        return value.count === "0" && value.type === "angel" && (<li key={i}>La carte {value.title}</li>)
                    })
                }
            </ol>
            <h2 className="text-xl font-medium mb-4">Voici les cartes démons à découvrir</h2>
            <ol className="list-decimal pl-10 mb-4">
                {
                    cards.map((value, i) => {
                        return value.count === "0" && value.type === "devil" && (<li key={i}>La carte {value.title}</li>)
                    })
                }
            </ol>
            <h2 className="text-xl font-medium mb-4">Voici les cartes animaux à découvrir</h2>
            <ol className="list-decimal pl-10 mb-4">
                {
                    cards.map((value, i) => {
                        return value.count === "0" && value.type === "animal" && (<li key={i}>La carte {value.title}</li>)
                    })
                }
            </ol>
        </div>
    )
}

export default Stats;