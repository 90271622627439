import { useEffect, useState } from "react"
import devilPic from '../img/devil.png'
import animalPic from '../img/animal.png'
import angelPic from '../img/angel.png'
import * as services from '../services/api'

function Card({card}) {
    const [data, setData] = useState([])
    useEffect(() => {
        if(data.length === 0) {
            setData(card)
        }
    }, [data, card])

    const postCard = (num) => {
        services.setCard({count: num, id: data.id}).then(data => {
            console.log(data);
        }).catch(err => {
            console.log(err)
        })
    }

    const handleCountMinus = () => {
        const dataSave = { ...data }
        dataSave.count = parseInt(dataSave.count) - 1
        setData(dataSave)
        postCard(dataSave.count)
    }
    const handleCountMore = () => {
        const dataSave = { ...data }
        dataSave.count = parseInt(dataSave.count) + 1
        setData(dataSave)
        postCard(dataSave.count)
    }
    
    const getPicture = () => {
        switch(data.type) {
            case "devil": return devilPic;
            case "animal": return animalPic;
            default: return angelPic;
        }
    }
    return (
        <li className={`p-4 mb-4 w-full ${data.display ? "md:flex":"hidden"}`}>
            <span className="md:w-28 h-28 flex justify-center items-center"><img src={getPicture()} className="card__image" alt="test" /></span>
            <div className="flex flex-col justify-between">
                <span className="text-3xl mb-4 md:mb-0 text-center md:text-left">{data.title}</span>
                <span>Dernière apparition : {data.date ? data.date : 'jamais'}</span>
                <div className="flex justify-between align-center md:justify-start">
                    <button onClick={handleCountMinus} className="rounded-lg bg-gray-100 w-8 h-8">-</button>
                    <span className="leading-8 mx-8">{data.count}</span>
                    <button onClick={handleCountMore} className="rounded-lg bg-gray-100 w-8 h-8">+</button>
                </div>
            </div>
        </li>
    )
}

export default Card;