import axios from 'axios';
import { API_URL, BASE_URL } from './settings';

export const getCards = async () => {
    const response = await axios.get(`${BASE_URL}`, {
        params: {
            api: 1,
            getcard: 1
        }
    });
    return response.data;
}


export const setCard = async (data) => {
    const response = await axios.get(`${BASE_URL}`, {
        params: {
            api: 1,
            setcard: 1,
            id: data.id,
            count: data.count
        }
    });
    // const response = await axios.post(`${BASE_URL}set-card`, { id: data.id, count: data.count },
    // {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // });
    return response.data;
}